import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";

const getData = async () => {
  return await axios.get(
    "https://cms.library.ethancollins.tech/items/books?fields=*,authors.*"
  );
};

const App = () => {
  const { data } = useQuery({
    queryFn: getData,
    queryKey: "books",
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const books = data?.data?.data;

  if (!books) return <h1>Error Loading Books...</h1>;

  const book = books[0];

  return (
    <Box component={Paper} width="max-content">
      <Stack alignItems="center" spacing={1} p={1}>
        <img
          src={`https://cms.library.ethancollins.tech/assets/${book.cover}`}
          alt="book cover"
          width={300}
        />
        <Typography variant="h4">{book?.title}</Typography>
        <Typography variant="h6">Authors:</Typography>
        {book.authors.map((author) => (
          <Typography>{author.name}</Typography>
        ))}
      </Stack>
    </Box>
  );
};

export default App;
